<template>
  <div class="home">
    <el-container>
      <el-header class="header" id="header">
        <top />
      </el-header>
      <el-main class="main">
        <swiper />
        <product />
        <wl />
        <about-me />
        <touch />
      </el-main>
      <el-footer class="footer">
        <foot />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import top from "../components/top/index.vue";
import swiper from "../components/swiper/index.vue";
import product from "../components/product/index.vue";
import wl from "../components/wl/index.vue";
import aboutMe from "../components/about/index.vue";
import touch from "../components/touch/index.vue";
import foot from "../components/footer/index.vue";
export default {
  name: "Home",
  components: {
    top,
    swiper,
    product,
    wl,
    aboutMe,
    touch,
    foot,
  },
};
</script>

<style scoped>
.main,
.header,
.footer {
  padding: 0;
}
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}
</style>