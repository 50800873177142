<template>
  <div class="wl" id="wl">
    <h3>物流行业&解决方案</h3>
    <header>
      物界科技聚焦的方向是“物算系统”，它将数字世界与物理世界连成一个感知、认知、决策、控制的紧密闭环，将系统与系统连接起来构建出一个分布式物算网络体系，连接各个小物流网，去构建出一个更大、更透明和更开放的协同物流网络；同时，基于“群智能（Swarm
      Intelligence）”的新一代机器人组件也将被集成到这个新的物算系统中，以协助提高全系统的效率。
    </header>
    <main>
      <div class="item">
        <img src="/images/business01.jpg" alt="" />
        <div>
          <h3>物联网+物流</h3>
          <p>
            物流的核心其实就是计算和处理各种“物”在不同时间和空间维度上不同性质、不同类型和不同要求的“流”的问题。要想解决大规模或大范围“流”的问题，就需要思考、设计和搭建新的网络。人类的智慧已经解决了许多不同领域“流”的问题，个中翘楚就是互联网的诞生。今天是科技大交汇的时代，人类对世界和宇宙的探索有了更多的想象力和征服手段，许多理论体系的研究在最近几年都有了实质性突破，具备了挑战未来物流新的理论体系和方法论。物界科技就是探索未来，实现“超世界存在”需求的创新公司。
          </p>
        </div>
      </div>
      <div class="item center-item">
        <img src="/images/business02.jpg" alt="" />
        <div>
          <h3>物联网+企业</h3>
          <p>
            物界科技的核心技术和能力包括感知融合算法，认知智能算法，运筹优化算法，机器人协同算法，智能仿真，智能硬件和机器人设计，以及物流和供应链解决方案等。
          </p>
        </div>
      </div>
      <div class="item">
        <img src="/images/business03.jpg" alt="" />
        <div>
          <h3>智慧城市</h3>
          <p>
            运用运筹优化领域最经典的问题之一，在现实生活中有广泛的应用场景。图1所示是一个配送场景中的VRP问题。简单的说，VRP解决的是在已知运输需求（货量、起始地、目的地）与车辆信息的前提下如何更好地规划车辆线路，以达到更低成本或者更快时效。感知城市状态，实现城市实时的数据采集与分析处理，让城市管理智慧化、精细化，为城市智慧化管理提供技术保障。
          </p>
        </div>
      </div>
    </main>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "wl",
};
</script>

<style scoped>
.wl {
  padding-top: 50px;
}
header {
  margin: 0 auto;
  width: 900px;
  text-align: center;
  line-height: 35px;
}
main {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
main > .item {
  width: 300px;
  height: 500px;
  border-radius: 8px;
  /* border: 1px solid #e9e9e9; */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 30px;
  padding: 5px;
  box-shadow: 0 0 10px #06c;
}
.center-item {
  margin: 0 30px;
}
.item > img {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  width: 300px;
  height: 150px;
}
</style>