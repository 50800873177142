<template>
  <div class="top" :class="{ active: showNavbarBg }">
    <div class="left">
      <img src="../../assets/logo.png" alt="" />
      <span>
        <marquee scrollAmount="5" width="200">物界科技</marquee>
      </span>
    </div>
    <div class="right" :class="{ active: showNavbarBg }">
      <div class="item">首页</div>
      <div class="item" @click="skip('product')">产品&解决方案</div>
      <div class="item" @click="skip('wl')">物流行业&解决方案</div>
      <div class="item" @click="skip('aboutus')">关于我们</div>
      <div class="item" @click="skip('touchus')">联系我们</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "top",
  data() {
    return {
      showNavbarBg: false,
    };
  },
  methods: {
    skip(keyword) {
      document.querySelector(`#${keyword}`).scrollIntoView(true);
    },
    handleWindowScroll() {
      const myTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const flag = myTop > 60;
      if (this.showNavbarBg != flag) {
        this.showNavbarBg = flag;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleWindowScroll);
  },
};
</script>

<style scoped>
.active {
  background-color: #000;
  color: #003954 !important;
  overflow: hidden;
}
.top {
  display: flex;
  height: 60px;
}
.left {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left img {
  width: 85px;
  height: 45px;
}
.left span {
  font-size: 22px;
  color: #fff;
}
.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.item {
  margin-top: 25px;
  margin-right: 12px;
  color: #fff;
  font-size: 18px;
}
.item:hover {
  color: turquoise;
  border-bottom: 2px solid #fff;
}
</style>