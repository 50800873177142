<template>
  <div>
    <div class="w">
      <el-carousel height="400px" :interval="3000">
        <el-carousel-item v-for="(item, index) in list_img" :key="index">
          <h3 class="small">
            <img :src="item.url" alt />
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "swiper",
  data() {
    return {
      list_img: [
        { url: "/images/banner01_big.png" },
        { url: "/images/banner02_big.jpg" },
      ],
    };
  },
};
</script>

<style scoped>
.w {
  margin: 0 auto;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 1;
  line-height: 300px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.small img {
  width: 100%;
  height: 100%;
}
</style>
