<template>
  <div class="touch" id="touchus">
    <h2>联系我们:</h2>
    <div class="address">
      <p>
        物界（上海）科技有限公司（简称物界）成立于2019年，物界科技产品是一个由πOS驱动的物流。物界科技作为一家深度理解“下一代智慧物流”的创新公司，着力开发基于Physical
        Internet理念的整套体系，希望助力中国物流生态最终实现整体智能升级。
      </p>
      <span
        >地址： 中国(上海)自由贸易试验区上科路366号、川和路55弄4号502室</span
      >
      <span>电话： 18802109797</span>
      <span>邮箱： amblexu@foxmail.com</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "touch",
};
</script>

<style scoped>
.touch {
  margin-top: 50px;
  background-color: #343a40;
  height: 160px;
  display: flex;
  padding: 0 60px;
}
.touch h2 {
  text-align: left;
  color: #fff;
  width: 150px;
}
.address {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  color: #fff;
  font-size: 14px;
}
</style>