<template>
  <div class="product" id="product">
    <h3>产品&解决方案</h3>
    <header>
      物界科技的核心技术和能力包括感知融合算法，认知智能算法，运筹优化算法，机器人协同算法，智能仿真，智能硬件和机器人设计，以及物流和供应链解决方案等。
    </header>
    <main>
      <div class="item">
        <img src="/images/product01.jpg" alt="" />
        <div class="content">
          <h3>智慧物流解决方案</h3>
          <p>
            “智慧物流”具有两大特点：第一个特征是互联互通、数据驱动，即所有物流要素互联互通并且数据化，以“数据”驱动一切洞察、决策、行动；第二个特征是深度协同、高效执行，即跨集团、跨企业、跨组织之间基于全局优化的智能算法深度协同，调整整个物流体系使各参与方得以高效的分工和协作。
          </p>
        </div>
      </div>
      <div class="item center-item">
        <img src="/images/13.png" alt="" />
        <div class="content">
          <h3>无人仓储解决方案</h3>
          <p>
            基于AGV的智能无人仓储系统，是以移动机器人作为承载平台，以智能仓储设计及管理优化算法为核心，通过搬运机器人协同及调度技术，结合仓储管理软件、自动化物流设备接口，共同实现智能化物流的现代仓储系统。
          </p>
        </div>
      </div>
      <div class="item">
        <img src="/images/12.png" alt="" />
        <div class="content">
          <h3>物联网解决方案</h3>
          <p>
           通过大数据、云计算、人工智能、智能硬件等技术手段，提高物流系统思维、感知、学习、分析决策和智能执行的能力，通过提升整个物流系统的智能化和自动化水平，推动发展、降低成本、提高效率。
          </p>
        </div>
      </div>
    </main>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "product",
};
</script>

<style scoped>
.product {
  padding-top: 50px;
}
header {
  margin: 0 auto;
  width: 900px;
  text-align: center;
  line-height: 35px;
}
main {
  display: flex;
  justify-content: center;
  align-items: center;
}
main > .item {
  width: 300px;
  height: 300px;
  padding: 2px;
  border-radius: 8px;
  box-shadow: 0 0 10px #06c;
  display: flex;
  align-items: center;
}
.center-item {
  margin: 0 20px;
}
.item img {
  height: 110px;
  width: 110px;
  border-radius: 50%;
}
.item > .content {
  flex: 1;
}
p {
  font-size: 14px;
}
</style>