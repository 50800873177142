<template>
  <div class="footer">Copyright© 2020 -物界科技</div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  height: 60px;
  line-height: 60px;
}
</style>