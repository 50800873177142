<template>
  <div class="about" id="aboutus">
    <img src="../../../public/images/about01.png" alt="" />
    <div class="aboutus">
      <h3>关于我们</h3>
      <span>about us</span><br />
      <span>让您更全面的了解我们</span>
      <p>
        <span class="special">物</span
        >界（上海）科技有限公司（简称物界）成立于2019年，物界科技产品是一个由πOS驱动的物流。物界科技作为一家深度理解“下一代智慧物流”的创新公司，
        着力开发基于PhysicalInternet理念的整套体系，希望助力中国物流生态最终实现整体智能升级。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutMe",
};
</script>

<style scoped>
.about {
  display: flex;
  padding: 0 250px;
  align-items: center;
  height: 300px;
}
.about > img {
  width: 400px;
}
.special {
  font-size: 30px;
  color: #17a2b8;
}
</style>